<!--统计报表-监测点日浓度报表-->
<template>
  <div>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.peorid"
            type="daterange"
            value-format="timestamp"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 350px"
            v-model="filter.columns"
            @change="getData"
            placeholder="污染物选择"
            multiple>
            <el-option label="油烟浓度" value="c_emissions"/>
            <el-option label="颗粒物浓度" value="c_granule"/>
            <el-option label="非甲烷总烃浓度" value="c_hdrocarbon"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="openExportSizeInput"
          >导出
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        :span-method="objectSpanMethod"
        size="mini"
        border
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55" :index="indexMethod"/>
        <el-table-column
          prop="locale_name"
          label="监测点"
          width="140"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="owner_name"
          width="200"
          label="所属单位"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="mn"
          label="设备编号"
          align="center"
          show-overflow-tooltip
          width="200"
          header-align="center"
        />
        <el-table-column
          prop="addr"
          label="地址"
          width="250"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="pollutant"
          label="采集项目"
          show-overflow-tooltip
          align="center"
          header-align="center">
          <template slot-scope="{row}">
            <div v-if="row.pollutant === 'c_emissions'">油烟浓度</div>
            <div v-else-if="row.pollutant === 'c_granule'">颗粒物浓度</div>
            <div v-else>非甲烷总烃浓度</div>
          </template>
        </el-table-column>
        <el-table-column label="日期" header-align="center">
          <el-table-column
            v-for="item in dateList"
            :label="item.split(' ')[0]"
            :key="item.locale_id"
            show-overflow-tooltip
            align="center"
            :formatter="(row)=>infoFormatter(row,item)"
            header-align="center">
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-col>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        @current-change="(v) => handlePageChange(v)"
        :current-page.sync="filter.page"
        layout="total, prev, pager, next, jumper"
        :total="datalist.length"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
  </div>
</template>
<script>

import {mapState} from "vuex";
import {getUserInfo, ownerJoin} from "@/util";
import dayjs from "dayjs";

export default {
  name: 'LocaleDailyReport',
  data() {
    return {
      filter: {
        page: 1,
        size: 20,
        owner: '',
        peorid: null,
        columns: ['c_emissions', 'c_granule', 'c_hdrocarbon']
      },
      loading: false,
      datalist: {
        content: [],
        length: 0
      },
      dateList: []
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapState(["clientHeight","customerTree"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: "Org", checkStrictly: true}),
    }),
  },
  
  methods: {
    getData() {
      this.loading = true
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 6);
        this.filter.peorid[0] = now.getTime();
      }
      var para = {
        page: this.filter.page,
        size: this.filter.size,
        is_download: false,
        start_time: this.filter.peorid[0] / 1000,
        end_time: this.filter.peorid[1] / 1000
      };
      const userInfo = getUserInfo().Info
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.owner = ownerJoin(this.filter.owner, userInfo)
      }
      if (this.filter.columns.length === 0) {
        para.columns = ['c_emissions', 'c_granule', 'c_hdrocarbon'].join(',')
      } else {
        para.columns = this.filter.columns.join(',')
      }
      this.datalist.content = []
      this.dateList = []
      this.$get("admin/daily_compute", para).then(res => {
        if (res.count === 0) {
          this.loading = false
          this.datalist.length = res.count
          return
        }
        let emptyList = []
        for (const listElement of res.list) {
          for (const pollutant of this.filter.columns) {
            let item = {
              locale_name: listElement.locale_name,
              mn: listElement.mn,
              addr: listElement.addr,
              locale_id: listElement.locale_id,
              owner_name: listElement.owner_name,
              pollutant: pollutant,
              list: listElement.list === null ? emptyList : listElement.list
            }
            this.datalist.content.push(item)
          }

          if (listElement.list !== null && listElement.list.length > this.dateList.length) {
            for (const date of listElement.list) {
              this.dateList.push(date.acquit_date)
              emptyList.push({
                c_emissions: 0,
                c_granule: 0,
                c_hdrocarbon: 0,
                acquit_date: date.acquit_date,
                online_status: 3
              })
            }
          }
        }
        if (this.dateList.length === 0) {
          this.dateList.push(dayjs().subtract(1, 'day').format("YYYY-MM-DD 00:00:00"))
        }
        this.datalist.length = res.count
        this.loading = false
      })
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.length} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleDownload(num) {
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 6);
        this.filter.peorid[0] = now.getTime();
      }
      var para = {
        page: this.filter.page,
        size: num,
        is_download: true,
        start_time: this.filter.peorid[0] / 1000,
        end_time: this.filter.peorid[1] / 1000
      };
      const userInfo = getUserInfo().Info
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.owner = ownerJoin(this.filter.owner, userInfo)
      }
      if (this.filter.columns.length === 0) {
        para.columns = ['c_emissions', 'c_granule', 'c_hdrocarbon'].join(',')
      } else {
        para.columns = this.filter.columns.join(',')
      }
      this.$get("admin/daily_compute", para).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    indexMethod(index) {
      return index / this.filter.columns.length + 1
    },
    infoFormatter(row, item) {
      let data = row.list.find(i => i.acquit_date === item)
      if (data && data.online_status) {
        if (data.online_status === 3 || data.online_status === 2) {
          return '-'
        } else {
          return data[row.pollutant]
        }
      } else {
        return '-'
      }

    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 4) {
        if (rowIndex % this.filter.columns.length === 0) {
          return {
            rowspan: this.filter.columns.length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    handlePageChange(val) {
      this.filter.page = val
      this.getData();
    },
  }
}
</script>
<style scoped lang="scss">

</style>
